import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchHistory } from '~/client/redux/actions/userEditActions'
import VideoTiles from '../VideoTiles'

function ContinueWatching({ user, request, fetchHistory, mweb, moduleData }) {
  useEffect(() => {
    if (user.auth) {
      fetchHistory()
    }
  }, [])

  if (!moduleData) return null

  const { err } = request

  const { watchHistory = {} } = user

  const { records = [] } = watchHistory

  if (err || !records.length || !user.auth) {
    return null
  }

  // moduleData.contentData = records.map((r) => {
  //   return r.contentResponse;
  // });

  moduleData.contentData = records.reduce((acc, r) => {
    const time = r.contentResponse.gist.watchedTime
    const runtime = r.contentResponse.gist.runtime

    if (time > 0 && time <= runtime - 10) {
      acc.push(r.contentResponse)
    }
    return acc
  }, [])

  return (
    <VideoTiles
      moduleData={{ ...moduleData }}
      s={{ thumbnailType: 'landscape' }}
      showProgressBar
    />
  )
}

export default connect(
  (state) => {
    return {
      request: state.request,
      user: state.user
    }
  },
  { fetchHistory }
)(ContinueWatching)
